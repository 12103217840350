import { Link } from "react-router-dom";
import Footer from "../component/Footer";
import Header from "../component/Header";
import ScrollToTop from "../component/ScrollToTop";
import ContactForm from "../component/ContactForm";



export default function Career() {
      const steps = [
    {
      id: 1,
      title: 'Application and Resume Review',
      description:
        'Start your journey by submitting your application and resume. Our team will carefully review your qualifications and experience.',
      img: '/path-to-image1', // Update with your image paths
    },
    {
      id: 2,
      title: 'Technical Assessment',
      description:
        'Showcase your skills through a technical assessment. This step ensures that your capabilities fit with the specific requirements of the role.',
      img: '/path-to-image2',
    },
    {
      id: 3,
      title: 'Understand Company Policy',
      description:
        'Get to know us better! Explore our company policies and engage in a joyful conversation to ensure alignment with our values and culture.',
      img: '/path-to-image3',
    },
    {
      id: 4,
      title: 'Final Selection and Offer',
      description:
        'Congratulations! Upon successful completion, final selection takes place, and we extend an offer, welcoming you to join our dynamic team.',
      img: '/path-to-image4',
    },
  ];
    return (
        <>
            <ScrollToTop>
                <Header />
                <div
                    className="breadcumb-wrapper"
                    style={{ backgroundImage: `url('./images/bg/breadcumb-bg1-1.jpg')` }}


                >
                    <div className="container">
                        <div className="breadcumb-content">
                            <h1 className="breadcumb-title">
                                        Career  
                            </h1>
                        </div>
                    </div>
                </div>

                <div className="why-area-1 space px-lg-5 ">
                    <div className="container-fluid">
                        <div className="row gx-5">
                            <div className="col-lg-4 mb-lg-0 mb-5">
                                <div className="title-area mb-45 ">
                                    <h6 className="mb-5"> Benefits</h6>
                                    <h2 className="sec-title">
                                    Why You Should Join Our Team?
                                    </h2>
                                    <div className="why-area-1 mt-0">
                                    <p>Come be a part of a workplace that values your well-being, growth, and happiness!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="bg-dark rounded-circle benefit_img d-flex justify-content-center ">
                                <img src="./images/career/first.svg" height="42" width="50" alt="" className="img-fluid " />
                                </div>
                                <h4 className="mt-35">Flexible Working Hours</h4>
                                <p>
                                         Enjoy work-life balance with our flexible working hours
                                </p>
                                <div className="bg-dark rounded-circle benefit_img d-flex justify-content-center">
                                <img src="../images/career/birthday-cake-icon.svg"  height="42" width="50" alt="" className="img-fluid" />
                                </div>
                                <h4 className="mt-35">
                                          Employee Birthday Celebrate

                                </h4>
                                <p className="">
                                          We believe in making your special day even more memorable
                                </p>
                                <div className="bg-dark rounded-circle benefit_img d-flex justify-content-center">
                                <img src="./images/career/third.svg" alt=""  height="42" width="50" className="img-fluid" />
                                </div>
                                <h4 className="mt-35">
                                         Friendly Paid leave
                                </h4>
                                <p>
                                         Take the time you need with our supportive and comprehensive paid leave policy.
                                </p>
                            </div>
                            <div className="col-lg-4 col-md-6">
                            <div className="bg-dark rounded-circle benefit_img d-flex justify-content-center ">
                                <img src="../images/career/forth.svg" alt=""  height="42" width="50" className="img-fluid" />
                                </div>
                                <h4 className="mt-35">
                                       Cooperative Seniors
                                </h4>
                                <p>
                                      Join a team where collaboration and support from seniors are at the forefront.
                                </p>

                                <div className="bg-dark rounded-circle benefit_img d-flex justify-content-center">
                                <img src="../images/career/fifth.svg" alt=""  height="42" width="50" className="img-fluid" />
                                </div>
                                <h4 className="mt-35">
                                      Employee of the Month Awards
                                </h4>
                                <p>
                                     Recognizing and rewarding excellence – your hard work doesn’t go unnoticed.
                                </p>
                                <div className="bg-dark rounded-circle benefit_img d-flex justify-content-center">
                                <img src="../images/career/sisth.svg" alt=""  height="42" width="50" className="img-fluid " />
                                </div>
                                <h4 className="mt-35">
                                       Annual Trip and Fun Activity
                                </h4>
                                <p>
                                        Unwind, connect, and create lasting memories with our annual trips and fun team activities.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        
             <section className="why-area-1 space  px-lg-5 bg-light">
                    <div className="container-fluid">
                        <h2>Recommended Jobs</h2>
                        <p>Curious about working with us? Dive into the Recommendation Job Section to read about the experiences and skills that fit with your talents. Join us for an enriching journey!</p>
                     <div className="py-5 ">
                        <div className="row g-4">
                            <div className="col-lg-4">
                                <div className="border">
                                <h3 className="bg-dark text-white p-4">Front End Developer</h3>
                                <div className="p-4">
                                <p className="d-flex gap-4">
                                    <span>
                                        <strong>Experience: </strong>2-5 Years
                                    </span>
                                    <span>
                                        <strong>Vacancy:</strong>
                                    </span>
                                </p>
                                {/* <button className="btn style1 wow img-custom-anim-left p-3 px-4" >Apply Now</button> */}
                                <a href="blog-details.html" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">Apply Now</span>
                                                <span className="effect-1">Apply Now</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </a>
                                        </div>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="border">
                                <h3 className="bg-dark text-white p-4">UI/UX Designer</h3>
                                <div className="p-4">
                                <p className="d-flex gap-4">
                                    <span>
                                        <strong>Experience: </strong>2-6 Years
                                    </span>
                                    <span>
                                        <strong>Vacancy:</strong>02
                                    </span>
                                </p>
                                {/* <button className="btn style1 wow img-custom-anim-left p-3 px-4" >Apply Now</button> */}
                                <a href="blog-details.html" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">Apply Now</span>
                                                <span className="effect-1">Apply Now</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </a>
                                        </div>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="border">
                                <h3 className="bg-dark text-white p-4">PHP Developer</h3>
                                <div className="p-4">
                                <p className="d-flex gap-4">
                                    <span>
                                        <strong>Experience: </strong>0-2 Years
                                    </span>
                                    <span>
                                        <strong>Vacancy:</strong>03
                                    </span>
                                </p>
                                {/* <button className="btn style1 wow img-custom-anim-left p-3 px-4" >Apply Now</button> */}
                                <a href="blog-details.html" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">Apply Now</span>
                                                <span className="effect-1">Apply Now</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </a>
                                        </div>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="border">
                                <h3 className="bg-dark text-white p-4">WordPress Developer</h3>
                                <div className="p-4">
                                <p className="d-flex gap-4">
                                    <span>
                                        <strong>Experience: </strong>1-3 Years
                                    </span>
                                    <span>
                                        <strong>Vacancy:</strong>02
                                    </span>
                                </p>
                                {/* <button className="btn style1 wow img-custom-anim-left p-3 px-4" >Apply Now</button> */}
                                <a href="blog-details.html" className="link-btn">
                                            <span className="link-effect">
                                                <span className="effect-1">Apply Now</span>
                                                <span className="effect-1">Apply Now</span>
                                            </span>
                                            <img src="./images/icon/arrow-left-top.svg" alt="icon" />
                                        </a>
                                        </div>
                                </div>

                            </div>
                        </div>
                        </div>
                    </div>
             </section>

           <section className="why-area-1 space  px-lg-5">
                <div className="container-fluid">
                    <div className="row g-5 align-items-center">
                    <div className="col-lg-5">
                        <h3>Application and Resume Review</h3>
                        <p>Start your journey by submitting your application and resume. Our team will carefully review your qualifications and experience.</p>
                    </div>
                    <div className="col-lg-2 d-flex justify-content-center">
                        <div className="bg-dark rounded-circle benefit_img d-flex justify-content-center">
                            
                            <h3 className="text-white d-flex justify-content-center align-items-center mb-0">
                            01
                            </h3>
                            
                           </div>
                           
                    </div>
                    <div className="col-lg-5">
                        <img src="../images/career/roadmap1.svg" alt="" />
                    </div>
                    <div className="col-lg-5">
                        <img src="../images/career/roadmap2.svg" alt="" />
                    </div>            
                    <div className="col-lg-2 d-flex justify-content-center">
                        <div className="bg-dark rounded-circle benefit_img d-flex justify-content-center">
                            <h3 className="text-white d-flex justify-content-center align-items-center mb-0">
                            02
                            </h3>
                           </div>
                    </div>
                    <div className="col-lg-5">
                    <h3>Technical Assessment
                    </h3>
                    <p>Showcase your skills through a technical assessment. This step ensures that your capabilities fit with the specific requirements of the role.</p>
                    </div>
                    <div className="col-lg-5">
                        <h3>Understand Company Policy
                        </h3>
                        <p>Get to know us better! Explore our company policies and engage in a joyful conversation to ensure alignment with our values and culture.</p>
                    </div>
                    <div className="col-lg-2  d-flex justify-content-center">
                        <div className="bg-dark rounded-circle benefit_img d-flex justify-content-center">
                            <h3 className="text-white d-flex justify-content-center align-items-center mb-0">
                            03
                            </h3>
                           </div>
                    </div>
                    <div className="col-lg-5">
                        <img src="../images/career/roadmap3.svg" alt="" />
                    </div>
                    <div className="col-lg-5">
                        <img src="../images/career/roadmap2.svg" alt="" />
                    </div>            
                    <div className="col-lg-2  d-flex justify-content-center">
                        <div className="bg-dark rounded-circle benefit_img d-flex justify-content-center">
                            <h3 className="text-white d-flex justify-content-center align-items-center mb-0">
                            04
                            </h3>
                           </div>
                    </div>
                    <div className="col-lg-5">
                    <h3>Final Selection and Offer
                    </h3>
                    <p>Congratulations! Upon successful completion, final selection takes place, and we extend an offer, welcoming you to join our dynamic team.</p>
                    </div>
                    </div>
                </div>
           </section>
               <ContactForm />

                <Footer />

                {/* Scroll To Top */}
                <div className="scroll-top">
                    <svg
                        className="progress-circle svg-content"
                        width="100%"
                        height="100%"
                        viewBox="-1 -1 102 102"
                    >
                        <path
                            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                            style={{
                                transition: "stroke-dashoffset 10ms linear 0s",
                                strokeDasharray: "307.919, 307.919",
                                strokeDashoffset: "307.919"
                            }}
                        />
                    </svg>
                </div>
            </ScrollToTop>
        </>

    )
}