import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <>
            <footer className="footer-wrapper footer-layout2 bg-white overflow-hidden">
                <div className="container">
                    <div className="widget-area space-top">
                        <div className="row justify-content-between">
                            <div className="col-md-6 col-xl-5 col-lg-7">
                                <div className="widget widget-newsletter footer-widget">
                                    <Link to="/">
                                        <img src="./images/hopefly-logo.png" alt="logo" className="mb-4" />
                                    </Link>
                                    <p>
                                        Hopefly is IT firm that specializes in all types of web and digital media solutions.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3 col-xl-2 col-lg-3">
                                <div className="widget widget_nav_menu footer-widget">
                                    <h3 className="widget_title">Links</h3>
                                    <div className="menu-all-pages-container list-column2">
                                        <ul className="menu">
                                            {/* <li>
                                                <Link to="/"> Home</Link>
                                            </li> */}
                                            <li>
                                                <Link to="/">Case Studies</Link>
                                            </li>
                                            <li>
                                                <Link to="/about"> About</Link>
                                            </li>
                                            <li>
                                                <Link to="/service">Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/career">Career</Link>
                                            </li>
                                            <li>
                                                <Link to="/blog">Blog</Link>
                                            </li>
                                            <li>
                                                <Link to="/contact">Contact</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-auto col-lg-4">
                                <div className="widget footer-widget widget-contact">
                                    <h3 className="widget_title">Contact</h3>
                                    <ul className="contact-info-list">
                                        <li>
                                            B-124 Royal Plaza, Varachha Main Rd, Near Bapa Sitaram Chowk,<br /> Nana Varachha,Surat, Gujarat 395011, India

                                        </li>
                                        <li>
                                            <Link to="tel:7487853421">+91 74878 53421</Link><br />
                                            <Link to="mailto:hopefly.meta@gmail.com">
                                                hopefly.meta@gmail.com
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright-wrap">
                        <div className="row gy-3 justify-content-between align-items-center">
                            <div className="col-md-6">
                                <p className="copyright-text">
                                    Copyright © 2024
                                    <Link to="/">Hopeflymeta</Link>
                                </p>
                            </div>
                            <div className="col-md-6 align-self-center">
                                <div className="social-btn style3 justify-content-md-end">
                                    <Link to="https://www.facebook.com/profile.php?id=61561637827918&sk=map"
                                        target="_blank">
                                        <span className="link-effect">
                                            <span className="effect-1">
                                                <i className="fab fa-facebook" />
                                            </span>
                                            <span className="effect-1">
                                                <i className="fab fa-facebook" />
                                            </span>
                                        </span>
                                    </Link>
                                    <Link to="https://instagram.com/" target="_blank">
                                        <span className="link-effect">
                                            <span className="effect-1">
                                                <i className="fab fa-instagram" />
                                            </span>
                                            <span className="effect-1">
                                                <i className="fab fa-instagram" />
                                            </span>
                                        </span>
                                    </Link>
                                    <Link to="https://x.com/HopeflyMeta" target="_blank">
                                        <span className="link-effect">
                                            <span className="effect-1">
                                                <i className="fab fa-twitter" />
                                            </span>
                                            <span className="effect-1">
                                                <i className="fab fa-twitter" />
                                            </span>
                                        </span>
                                    </Link>
                                    {/* <Link to="https://dribbble.com/" target="_blank">
                                        <span className="link-effect">
                                            <span className="effect-1">
                                                <i className="fab fa-dribbble" />
                                            </span>
                                            <span className="effect-1">
                                                <i className="fab fa-dribbble" />
                                            </span>
                                        </span>
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}